// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SjclWithAll from "sjcl-with-all";

var BitArray = {};

var Bn = {};

var Hex = {};

var Utf8String = {};

var Sha256 = {};

var Misc = {};

var Aes = {};

var Cipher = {
  Aes: Aes
};

var CCM = {};

var Mode = {
  CCM: CCM
};

var Random = {};

var Curve = {};

var Point = {};

var Ecc = {
  Curve: Curve,
  Point: Point
};

function toHex(t) {
  return t.serialize().point;
}

function fromHex(str) {
  return new (SjclWithAll.ecc.ecdsa.publicKey)(SjclWithAll.ecc.curves.c256, SjclWithAll.bn.fromBits(SjclWithAll.codec.hex.toBits(str)));
}

var PublicKey = {
  toHex: toHex,
  fromHex: fromHex
};

function toHex$1(t) {
  return t.serialize().exponent;
}

function fromHex$1(str) {
  return new (SjclWithAll.ecc.ecdsa.secretKey)(SjclWithAll.ecc.curves.c256, SjclWithAll.bn.fromBits(SjclWithAll.codec.hex.toBits(str)));
}

var SecretKey = {
  toHex: toHex$1,
  fromHex: fromHex$1
};

function generateKeys(param) {
  return SjclWithAll.ecc.ecdsa.generateKeys(undefined, undefined, undefined);
}

function generateKeysFromSecretKey(sec) {
  return SjclWithAll.ecc.ecdsa.generateKeys(undefined, undefined, Caml_option.some(sec));
}

function $$new(param) {
  var keys = SjclWithAll.ecc.ecdsa.generateKeys(undefined, undefined, undefined);
  return [
          keys.pub,
          keys.sec
        ];
}

var Ecdsa = {
  PublicKey: PublicKey,
  SecretKey: SecretKey,
  generateKeys: generateKeys,
  generateKeysFromSecretKey: generateKeysFromSecretKey,
  $$new: $$new
};

import * as Crypto from 'expo-crypto'
var ab = new Uint32Array(32);
Crypto.getRandomValues(ab)
;

var ab = ab;

SjclWithAll.random.addEntropy(ab, 1024, "expo-crypto");

export {
  BitArray ,
  Bn ,
  Hex ,
  Utf8String ,
  Sha256 ,
  Misc ,
  Cipher ,
  Mode ,
  Random ,
  Ecc ,
  Ecdsa ,
  ab ,
}
/*  Not a pure module */
