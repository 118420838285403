// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as State from "../state/State.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InviteQuery from "../helpers/InviteQuery.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativePaper from "react-native-paper";

function ElectionInvitePhone$Item(Props) {
  var onRemove = Props.onRemove;
  var onUpdate = Props.onUpdate;
  var phone = Props.phone;
  var index = Props.index;
  return React.createElement(S.Row.make, {
              children: null,
              style: {
                marginHorizontal: 20.0
              }
            }, React.createElement(S.Col.make, {
                  children: React.createElement(ReactNativePaper.TextInput, {
                        mode: "flat",
                        label: "Phone " + String(index) + "",
                        value: phone,
                        onChangeText: onUpdate,
                        testID: "input-invite-phone-" + String(index) + ""
                      }),
                  style: {
                    flexGrow: 10.0
                  }
                }), React.createElement(S.Col.make, {
                  children: React.createElement(ReactNativePaper.Button, {
                        onPress: onRemove,
                        children: React.createElement(ReactNativePaper.List.Icon, {
                              icon: "delete"
                            })
                      })
                }));
}

var Item = {
  make: ElectionInvitePhone$Item
};

function ElectionInvitePhone(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = React.useState(function () {
        return [
                "",
                ""
              ];
      });
  var setPhones = match$1[1];
  var phones = match$1[0];
  var admin = State.getElectionAdminExn(match[0], election);
  var match$2 = React.useState(function () {
        return true;
      });
  var setSendInvite = match$2[1];
  var sendInvite = match$2[0];
  var onSubmit = function (param) {
    Belt_Array.forEach(Belt_Array.keep(phones, (function (phone) {
                return phone !== "";
              })), (function (phone) {
            InviteQuery.send(dispatch, admin, "phone", phone, electionId, sendInvite);
          }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election,
                  section: "inviteMail"
                }), Belt_Array.mapWithIndex(phones, (function (i, phone) {
                    return React.createElement(ElectionInvitePhone$Item, {
                                onRemove: (function (param) {
                                    Curry._1(setPhones, (function (__x) {
                                            return Belt_Array.keepWithIndex(__x, (function (param, index) {
                                                          return index !== i;
                                                        }));
                                          }));
                                  }),
                                onUpdate: (function (phone) {
                                    Curry._1(setPhones, (function (phones) {
                                            return Belt_Array.mapWithIndex(phones, (function (index, oldPhone) {
                                                          if (index === i) {
                                                            return phone;
                                                          } else {
                                                            return oldPhone;
                                                          }
                                                        }));
                                          }));
                                  }),
                                phone: phone,
                                index: i + 1 | 0,
                                key: String(i)
                              });
                  })), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(setPhones, (function (phones) {
                              return Belt_Array.concat(phones, [""]);
                            }));
                    }),
                  title: "+",
                  style: {
                    width: 100.0
                  }
                }), React.createElement(ReactNativePaper.List.Item, {
                  onPress: (function (param) {
                      Curry._1(setSendInvite, (function (b) {
                              return !b;
                            }));
                    }),
                  title: "Envoyer une invitation",
                  description: "Tous les participants recevront un SMS",
                  right: (function (param) {
                      return React.createElement(ReactNativePaper.Switch, {
                                  value: sendInvite
                                });
                    })
                }), React.createElement(S.Button.make, {
                  onPress: onSubmit,
                  title: "Inviter"
                }));
}

var make = ElectionInvitePhone;

export {
  Item ,
  make ,
}
/* S Not a pure module */
