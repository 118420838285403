// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

var initial_events = [];

var initial_accounts = [];

var initial_trustees = [];

var initial_invitations = [];

var initial_route = {
  hd: "",
  tl: /* [] */0
};

var initial_ballots = [];

var initial = {
  events: initial_events,
  fetchingEvents: true,
  accounts: initial_accounts,
  trustees: initial_trustees,
  invitations: initial_invitations,
  route: initial_route,
  elections: undefined,
  electionLatestIds: undefined,
  ballots: initial_ballots
};

function getAccount(state, publicKey) {
  return Belt_Array.getBy(state.accounts, (function (id) {
                return publicKey === id.userId;
              }));
}

function getAccountExn(state, publicKey) {
  return Belt_Option.getExn(getAccount(state, publicKey));
}

function getInvitation(state, userId) {
  return Belt_Array.getBy(state.invitations, (function (invitation) {
                return userId === invitation.userId;
              }));
}

function getInvitationExn(state, userId) {
  return Belt_Option.getExn(getInvitation(state, userId));
}

function getElectionValidBallots(state, electionId) {
  return Js_dict.values(Js_dict.fromArray(Belt_Array.keep(state.ballots, (function (ballot) {
                          return ballot.electionId === electionId;
                        })).map(function (ballot) {
                      return [
                              ballot.voterId,
                              ballot
                            ];
                    })));
}

function getElectionAdmin(state, election) {
  return Belt_Array.getBy(state.accounts, (function (account) {
                return Belt_Option.isSome(Belt_Array.getBy(election.adminIds, (function (userId) {
                                  return userId === account.userId;
                                })));
              }));
}

function getElectionAdminExn(state, election) {
  return Belt_Option.getExn(getElectionAdmin(state, election));
}

export {
  initial ,
  getAccount ,
  getAccountExn ,
  getInvitation ,
  getInvitationExn ,
  getElectionValidBallots ,
  getElectionAdmin ,
  getElectionAdminExn ,
}
/* No side effect */
