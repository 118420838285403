// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belenios from "../helpers/Belenios.bs.js";

function answers(election) {
  return Belenios.Election.answers(JSON.parse(election.params));
}

function name(election) {
  return JSON.parse(election.params).name;
}

function description(election) {
  return JSON.parse(election.params).description;
}

var choices = answers;

export {
  answers ,
  choices ,
  name ,
  description ,
}
/* Belenios Not a pure module */
