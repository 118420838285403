// Generated by ReScript, PLEASE EDIT WITH CARE

import * as State from "./State.bs.js";
import * as React from "react";

var context = React.createContext(State.initial);

var provider = context.Provider;

function StateContext$State$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider, {
              value: value,
              children: children
            });
}

var Provider = {
  provider: provider,
  make: StateContext$State$Provider
};

function use(param) {
  return React.useContext(context);
}

var State$1 = {
  context: context,
  Provider: Provider,
  use: use
};

var context$1 = React.createContext(function (_action) {
      
    });

var provider$1 = context$1.Provider;

function StateContext$Dispatch$Provider(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement(provider$1, {
              value: value,
              children: children
            });
}

var Provider$1 = {
  provider: provider$1,
  make: StateContext$Dispatch$Provider
};

function use$1(param) {
  return React.useContext(context$1);
}

var Dispatch = {
  context: context$1,
  Provider: Provider$1,
  use: use$1
};

function use$2(param) {
  return [
          React.useContext(context),
          React.useContext(context$1)
        ];
}

export {
  State$1 as State,
  Dispatch ,
  use$2 as use,
}
/* context Not a pure module */
