// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Trustee from "../model/Trustee.bs.js";
import * as Belenios from "../helpers/Belenios.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function TrusteeIndex(Props) {
  var match = StateContext.use(undefined);
  var match$1 = ReactI18next.useTranslation();
  var t = match$1.t;
  return React.createElement(ReactNativePaper.List.Section, {
              title: t("trustees.title"),
              children: null
            }, Belt_Array.map(match[0].trustees, (function (trustee) {
                    var privkey = trustee.privkey;
                    var pubkey = Belenios.Trustees.pubkey(trustee.trustees);
                    return React.createElement(ReactNativePaper.List.Item, {
                                title: pubkey,
                                description: privkey,
                                key: pubkey
                              });
                  })), React.createElement(ReactNativePaper.Button, {
                  mode: "outlined",
                  onPress: (function (param) {
                      Trustee.clear(undefined);
                    }),
                  children: t("trustees.clear")
                }));
}

var make = TrusteeIndex;

export {
  make ,
}
/* react Not a pure module */
