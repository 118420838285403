// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as StateContext from "../state/StateContext.bs.js";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativePaper from "react-native-paper";

function ElectionInvite(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election,
                  section: "invite"
                }), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Navigate */0,
                            _0: {
                              hd: "elections",
                              tl: {
                                hd: electionId,
                                tl: {
                                  hd: "invite_email",
                                  tl: /* [] */0
                                }
                              }
                            }
                          });
                    }),
                  title: "Invite by email",
                  testID: "button-invite-email"
                }), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Navigate */0,
                            _0: {
                              hd: "elections",
                              tl: {
                                hd: electionId,
                                tl: {
                                  hd: "invite_phone",
                                  tl: /* [] */0
                                }
                              }
                            }
                          });
                    }),
                  title: "Invite by phone",
                  testID: "button-invite-phone"
                }), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Navigate */0,
                            _0: {
                              hd: "elections",
                              tl: {
                                hd: electionId,
                                tl: {
                                  hd: "invite_link",
                                  tl: /* [] */0
                                }
                              }
                            }
                          });
                    }),
                  title: "Invite by link",
                  testID: "button-invite-link"
                }), React.createElement(ReactNativePaper.Button, {
                  mode: "text",
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Navigate */0,
                            _0: {
                              hd: "elections",
                              tl: {
                                hd: electionId,
                                tl: {
                                  hd: "invite_manage",
                                  tl: /* [] */0
                                }
                              }
                            }
                          });
                    }),
                  children: "Gérer les invitations"
                }));
}

var make = ElectionInvite;

export {
  make ,
}
/* S Not a pure module */
