// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as SIcon from "./icons/SIcon.bs.js";
import * as React from "react";
import * as ReactNative from "react-native";

function ElectionNewChoiceItem(Props) {
  var onRemove = Props.onRemove;
  var onUpdate = Props.onUpdate;
  var name = Props.name;
  var index = Props.index;
  return React.createElement(S.Row.make, {
              children: null,
              style: {
                marginHorizontal: 20.0
              }
            }, React.createElement(S.Col.make, {
                  children: React.createElement(S.TextInput.make, {
                        testID: "choice-" + String(index) + "",
                        value: name,
                        onChangeText: onUpdate,
                        placeholder: "Choice " + String(index) + ""
                      }),
                  style: {
                    flexGrow: 10.0
                  }
                }), React.createElement(S.Col.make, {
                  children: React.createElement(ReactNative.TouchableOpacity, {
                        onPress: onRemove,
                        children: React.createElement(SIcon.ButtonCross.make, {})
                      })
                }));
}

var make = ElectionNewChoiceItem;

export {
  make ,
}
/* S Not a pure module */
