// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import LogoPng from "./Logo.png";
import * as ReactNative from "react-native";

var source = LogoPng;

var style = {
  alignSelf: "center",
  height: 139.0,
  width: 360.0
};

function Logo(Props) {
  return React.createElement(ReactNative.Image, {
              source: source,
              style: style
            });
}

var make = Logo;

export {
  source ,
  style ,
  make ,
}
/* source Not a pure module */
