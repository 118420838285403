// Generated by ReScript, PLEASE EDIT WITH CARE

import * as X from "./X.bs.js";
import * as $$URL from "./URL.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Event_ from "../model/Event_.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Pervasives from "rescript/lib/es6/pervasives.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";

function send(dispatch, admin, type_, username, electionId, sendInvite) {
  var dict = {};
  dict["username"] = username;
  dict["type"] = type_;
  dict["electionId"] = electionId;
  dict["sendInvite"] = sendInvite;
  X.post("" + $$URL.server_auth_email + "/users", dict).then(function (prim) {
            return prim.json();
          }).then(function (json) {
          var value = Js_json.classify(json);
          if (typeof value === "number" || value.TAG !== /* JSONObject */2) {
            return Pervasives.failwith("Expected an object");
          } else {
            return Promise.resolve(Belt_Option.flatMap(Js_dict.get(value._0, "managerId"), Js_json.decodeString));
          }
        }).then(function (optionalManagerId) {
        if (optionalManagerId !== undefined) {
          var invitation_email = username;
          var invitation = {
            userId: optionalManagerId,
            email: invitation_email,
            phoneNumber: undefined
          };
          Curry._1(dispatch, {
                TAG: /* Invitation_Add */5,
                _0: invitation
              });
          var ev = Event_.ElectionVoter.create({
                electionId: electionId,
                voterId: optionalManagerId
              }, admin);
          Curry._1(dispatch, {
                TAG: /* Event_Add_With_Broadcast */3,
                _0: ev
              });
          return Curry._1(dispatch, {
                      TAG: /* Navigate */0,
                      _0: {
                        hd: "elections",
                        tl: {
                          hd: electionId,
                          tl: /* [] */0
                        }
                      }
                    });
        }
        console.log("No managerId found...");
      });
}

export {
  send ,
}
/* X Not a pure module */
