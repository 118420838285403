// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../../helpers/S.bs.js";
import * as React from "react";
import * as StateContext from "../../state/StateContext.bs.js";
import * as ReactNativePaper from "react-native-paper";

function NotFoundYet(Props) {
  var match = StateContext.use(undefined);
  var text = match[0].fetchingEvents ? "Please wait. Loading in progress..." : "Object is not found.";
  return React.createElement(ReactNativePaper.Text, {
              style: S.flatten([
                    S.title,
                    {
                      marginTop: 50.0
                    }
                  ]),
              children: text
            });
}

var make = NotFoundYet;

export {
  make ,
}
/* S Not a pure module */
