// Generated by ReScript, PLEASE EDIT WITH CARE

import * as State from "../state/State.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativePaper from "react-native-paper";

function ElectionInviteManage$Item(Props) {
  var userId = Props.userId;
  var match = StateContext.use(undefined);
  var invitation = State.getInvitationExn(match[0], userId);
  var email = Belt_Option.getWithDefault(invitation.email, "No email");
  return React.createElement(ReactNativePaper.List.Item, {
              title: userId,
              description: email
            });
}

var Item = {
  make: ElectionInviteManage$Item
};

function ElectionInviteManage(Props) {
  var election = Props.election;
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election,
                  section: "inviteManage"
                }), Belt_Array.map(election.voterIds, (function (userId) {
                    return React.createElement(ElectionInviteManage$Item, {
                                userId: userId,
                                key: userId
                              });
                  })));
}

var make = ElectionInviteManage;

export {
  Item ,
  make ,
}
/* react Not a pure module */
