// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function IdentityShow(Props) {
  var publicKey = Props.publicKey;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var state = match[0];
  var match$1 = ReactI18next.useTranslation();
  var t = match$1.t;
  var identity = Belt_Array.getBy(state.accounts, (function (id) {
          return id.userId === publicKey;
        }));
  var secretKey = identity !== undefined ? identity.secret : "";
  Belt_Array.keep(state.ballots, (function (ballot) {
          return ballot.voterId === publicKey;
        }));
  var elections = [];
  return React.createElement(React.Fragment, undefined, React.createElement(ReactNativePaper.List.Section, {
                  title: t("identity.show.title"),
                  children: null
                }, React.createElement(ReactNativePaper.List.Item, {
                      title: t("identity.show.publicKey"),
                      description: publicKey
                    }), React.createElement(ReactNativePaper.List.Item, {
                      title: t("identity.show.secretKey"),
                      description: secretKey
                    }), React.createElement(ReactNativePaper.List.Section, {
                      title: t("identity.show.elections"),
                      children: Belt_Array.map(elections, (function (param) {
                              var eventHash = param[0];
                              return React.createElement(ReactNativePaper.List.Item, {
                                          onPress: (function (param) {
                                              Curry._1(dispatch, {
                                                    TAG: /* Navigate */0,
                                                    _0: {
                                                      hd: "elections",
                                                      tl: {
                                                        hd: eventHash,
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  });
                                            }),
                                          title: eventHash,
                                          key: eventHash
                                        });
                            }))
                    })));
}

var make = IdentityShow;

export {
  make ,
}
/* react Not a pure module */
