// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as React from "react";
import * as ReactNativePaper from "react-native-paper";

function ElectionInviteEmailItem(Props) {
  var onRemove = Props.onRemove;
  var onUpdate = Props.onUpdate;
  var email = Props.email;
  var index = Props.index;
  return React.createElement(S.Row.make, {
              children: null,
              style: {
                marginHorizontal: 20.0
              }
            }, React.createElement(S.Col.make, {
                  children: React.createElement(ReactNativePaper.TextInput, {
                        mode: "flat",
                        label: "Email " + String(index) + "",
                        value: email,
                        onChangeText: onUpdate,
                        testID: "input-invite-email-" + String(index) + ""
                      }),
                  style: {
                    flexGrow: 10.0
                  }
                }), React.createElement(S.Col.make, {
                  children: React.createElement(ReactNativePaper.Button, {
                        onPress: onRemove,
                        children: React.createElement(ReactNativePaper.List.Icon, {
                              icon: "delete"
                            })
                      })
                }));
}

var make = ElectionInviteEmailItem;

export {
  make ,
}
/* S Not a pure module */
