// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as React from "react";
import * as Election from "../model/Election.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function ElectionShowChoices(Props) {
  var election = Props.election;
  var match = ReactI18next.useTranslation();
  var question = Election.description(election);
  var question$1 = question === "" ? match.t("election.new.question") : question;
  return React.createElement(ReactNative.View, {
              style: S.questionBox,
              children: null
            }, React.createElement(S.Section.make, {
                  title: question$1
                }), Belt_Array.mapWithIndex(Election.choices(election), (function (i, name) {
                    return React.createElement(ReactNativePaper.List.Item, {
                                title: name,
                                key: String(i)
                              });
                  })));
}

var make = ElectionShowChoices;

export {
  make ,
}
/* S Not a pure module */
