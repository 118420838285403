// Generated by ReScript, PLEASE EDIT WITH CARE


var Dimensions = require('react-native').Dimensions
;

function width(param) {
  return (Dimensions.get('window').width);
}

function height(param) {
  return (Dimensions.get('window').height);
}

export {
  width ,
  height ,
}
/*  Not a pure module */
