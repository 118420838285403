// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as State from "../state/State.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as InviteQuery from "../helpers/InviteQuery.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativePaper from "react-native-paper";
import * as ElectionInviteEmailItem from "./ElectionInviteEmailItem.bs.js";

function ElectionInviteEmail(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = React.useState(function () {
        return [
                "",
                ""
              ];
      });
  var setEmails = match$1[1];
  var emails = match$1[0];
  var admin = State.getElectionAdminExn(match[0], election);
  var match$2 = React.useState(function () {
        return true;
      });
  var setSendInvite = match$2[1];
  var sendInvite = match$2[0];
  var onSubmit = function (param) {
    Belt_Array.forEach(Belt_Array.keep(emails, (function (email) {
                return email !== "";
              })), (function (email) {
            InviteQuery.send(dispatch, admin, "email", email, electionId, sendInvite);
          }));
  };
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election,
                  section: "inviteMail"
                }), Belt_Array.mapWithIndex(emails, (function (i, email) {
                    return React.createElement(ElectionInviteEmailItem.make, {
                                onRemove: (function (param) {
                                    Curry._1(setEmails, (function (__x) {
                                            return Belt_Array.keepWithIndex(__x, (function (param, index) {
                                                          return index !== i;
                                                        }));
                                          }));
                                  }),
                                onUpdate: (function (email) {
                                    Curry._1(setEmails, (function (emails) {
                                            return Belt_Array.mapWithIndex(emails, (function (index, oldEmail) {
                                                          if (index === i) {
                                                            return email;
                                                          } else {
                                                            return oldEmail;
                                                          }
                                                        }));
                                          }));
                                  }),
                                email: email,
                                index: i + 1 | 0,
                                key: String(i)
                              });
                  })), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(setEmails, (function (emails) {
                              return Belt_Array.concat(emails, [""]);
                            }));
                    }),
                  title: "+",
                  style: {
                    width: 100.0
                  }
                }), React.createElement(ReactNativePaper.List.Item, {
                  onPress: (function (param) {
                      Curry._1(setSendInvite, (function (b) {
                              return !b;
                            }));
                    }),
                  title: "Envoyer une invitation",
                  description: "Tous les participants recevront un email",
                  right: (function (param) {
                      return React.createElement(ReactNativePaper.Switch, {
                                  value: sendInvite
                                });
                    })
                }), React.createElement(S.Button.make, {
                  onPress: onSubmit,
                  title: "Inviter"
                }));
}

var make = ElectionInviteEmail;

export {
  make ,
}
/* S Not a pure module */
