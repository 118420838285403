// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as State from "./state/State.bs.js";
import * as React from "react";
import * as Layout from "./components/Layout.bs.js";
import * as UseTea from "./helpers/UseTea.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as HomeView from "./components/HomeView.bs.js";
import * as EventIndex from "./components/EventIndex.bs.js";
import * as ElectionNew from "./components/ElectionNew.bs.js";
import * as NotFoundYet from "./components/utils/NotFoundYet.bs.js";
import * as ContactIndex from "./components/ContactIndex.bs.js";
import * as ElectionShow from "./components/ElectionShow.bs.js";
import * as IdentityShow from "./components/IdentityShow.bs.js";
import * as SettingsView from "./components/SettingsView.bs.js";
import * as StateReducer from "./state/StateReducer.bs.js";
import * as TrusteeIndex from "./components/TrusteeIndex.bs.js";
import * as ReactNative from "react-native";
import * as ElectionBooth from "./components/ElectionBooth.bs.js";
import * as IdentityIndex from "./components/IdentityIndex.bs.js";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ElectionInvite from "./components/ElectionInvite.bs.js";
import * as ElectionResult from "./components/ElectionResult.bs.js";
import * as ElectionSearch from "./components/ElectionSearch.bs.js";
import * as ElectionChallenge from "./components/ElectionChallenge.bs.js";
import * as ElectionInviteLink from "./components/ElectionInviteLink.bs.js";
import * as ElectionInviteEmail from "./components/ElectionInviteEmail.bs.js";
import * as ElectionInvitePhone from "./components/ElectionInvitePhone.bs.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as ElectionInviteManage from "./components/ElectionInviteManage.bs.js";

function App(Props) {
  var match = UseTea.useTea(StateReducer.reducer, State.initial);
  var dispatch = match[1];
  var state = match[0];
  React.useEffect((function () {
          Curry._1(dispatch, /* Reset */0);
        }), []);
  React.useEffect((function () {
          setInterval((function (param) {
                  Curry._1(dispatch, /* FetchLatest */3);
                }), 5000);
        }), []);
  if (ReactNative.Platform.OS === "web") {
    var url = RescriptReactRouter.useUrl(undefined, undefined);
    if (Caml_obj.notequal(url.path, state.route)) {
      Curry._1(dispatch, {
            TAG: /* Navigate */0,
            _0: url.path
          });
    }
    
  }
  var route = state.route;
  var tmp;
  var exit = 0;
  if (route) {
    switch (route.hd) {
      case "" :
          if (route.tl) {
            exit = 1;
          } else {
            tmp = React.createElement(HomeView.make, {});
          }
          break;
      case "contacts" :
          if (route.tl) {
            exit = 1;
          } else {
            tmp = React.createElement(ContactIndex.make, {});
          }
          break;
      case "elections" :
          var match$1 = route.tl;
          if (match$1) {
            var electionId = match$1.hd;
            var exit$1 = 0;
            switch (electionId) {
              case "new" :
                  if (match$1.tl) {
                    exit$1 = 2;
                  } else {
                    tmp = React.createElement(ElectionNew.make, {});
                  }
                  break;
              case "search" :
                  if (match$1.tl) {
                    exit$1 = 2;
                  } else {
                    tmp = React.createElement(ElectionSearch.make, {});
                  }
                  break;
              default:
                exit$1 = 2;
            }
            if (exit$1 === 2) {
              var electionRoute = match$1.tl;
              var election = Belt_MapString.get(state.elections, electionId);
              if (election !== undefined) {
                var exit$2 = 0;
                if (electionRoute) {
                  switch (electionRoute.hd) {
                    case "booth" :
                        if (electionRoute.tl) {
                          exit$2 = 3;
                        } else {
                          tmp = React.createElement(ElectionBooth.make, {
                                election: election,
                                electionId: electionId
                              });
                        }
                        break;
                    case "challenge" :
                        var match$2 = electionRoute.tl;
                        if (match$2 && !match$2.tl) {
                          tmp = React.createElement(ElectionChallenge.make, {
                                election: election,
                                electionId: electionId,
                                userToken: match$2.hd
                              });
                        } else {
                          exit$2 = 3;
                        }
                        break;
                    case "invite" :
                        if (electionRoute.tl) {
                          exit$2 = 3;
                        } else {
                          tmp = React.createElement(ElectionInvite.make, {
                                election: election,
                                electionId: electionId
                              });
                        }
                        break;
                    case "invite_email" :
                        if (electionRoute.tl) {
                          exit$2 = 3;
                        } else {
                          tmp = React.createElement(ElectionInviteEmail.make, {
                                election: election,
                                electionId: electionId
                              });
                        }
                        break;
                    case "invite_link" :
                        if (electionRoute.tl) {
                          exit$2 = 3;
                        } else {
                          tmp = React.createElement(ElectionInviteLink.make, {
                                election: election,
                                electionId: electionId
                              });
                        }
                        break;
                    case "invite_manage" :
                        if (electionRoute.tl) {
                          exit$2 = 3;
                        } else {
                          tmp = React.createElement(ElectionInviteManage.make, {
                                election: election
                              });
                        }
                        break;
                    case "invite_phone" :
                        if (electionRoute.tl) {
                          exit$2 = 3;
                        } else {
                          tmp = React.createElement(ElectionInvitePhone.make, {
                                election: election,
                                electionId: electionId
                              });
                        }
                        break;
                    case "result" :
                        if (electionRoute.tl) {
                          exit$2 = 3;
                        } else {
                          tmp = React.createElement(ElectionResult.make, {
                                election: election,
                                electionId: electionId
                              });
                        }
                        break;
                    default:
                      exit$2 = 3;
                  }
                } else {
                  tmp = React.createElement(ElectionShow.make, {
                        election: election,
                        electionId: electionId
                      });
                }
                if (exit$2 === 3) {
                  console.log([
                        "Unknown election route",
                        electionRoute
                      ]);
                  tmp = React.createElement(HomeView.make, {});
                }
                
              } else {
                tmp = React.createElement(NotFoundYet.make, {});
              }
            }
            
          } else {
            exit = 1;
          }
          break;
      case "events" :
          if (route.tl) {
            exit = 1;
          } else {
            tmp = React.createElement(EventIndex.make, {});
          }
          break;
      case "identities" :
          var match$3 = route.tl;
          if (match$3) {
            if (match$3.tl) {
              exit = 1;
            } else {
              tmp = React.createElement(IdentityShow.make, {
                    publicKey: match$3.hd
                  });
            }
          } else {
            tmp = React.createElement(IdentityIndex.make, {});
          }
          break;
      case "settings" :
          if (route.tl) {
            exit = 1;
          } else {
            tmp = React.createElement(SettingsView.make, {});
          }
          break;
      case "trustees" :
          if (route.tl) {
            exit = 1;
          } else {
            tmp = React.createElement(TrusteeIndex.make, {});
          }
          break;
      default:
        exit = 1;
    }
  } else {
    tmp = React.createElement(HomeView.make, {});
  }
  if (exit === 1) {
    console.log([
          "Unknown route",
          route
        ]);
    tmp = React.createElement(HomeView.make, {});
  }
  return React.createElement(Layout.make, {
              state: state,
              dispatch: dispatch,
              children: tmp
            });
}

var make = App;

export {
  make ,
}
/* react Not a pure module */
