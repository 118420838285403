// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function CopyButton(Props) {
  var text = Props.text;
  var match = ReactI18next.useTranslation();
  var t = match.t;
  var match$1 = React.useState(function () {
        return false;
      });
  var setVisible = match$1[1];
  return React.createElement(React.Fragment, undefined, React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      navigator.clipboard.writeText(text);
                      Curry._1(setVisible, (function (param) {
                              return true;
                            }));
                    }),
                  title: t("utils.copybutton.text")
                }), React.createElement(ReactNativePaper.Portal, {
                  children: React.createElement(ReactNativePaper.Snackbar, {
                        duration: 2000,
                        onDismiss: (function (param) {
                            Curry._1(setVisible, (function (param) {
                                    return false;
                                  }));
                          }),
                        visible: match$1[0],
                        children: t("utils.copybutton.feedback")
                      })
                }));
}

var make = CopyButton;

export {
  make ,
}
/* S Not a pure module */
