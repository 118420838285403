// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belenios from "../helpers/Belenios.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as AsyncStorage from "@react-native-async-storage/async-storage";

function make(param) {
  var match = Belenios.Trustees.create(undefined);
  return {
          trustees: match[1],
          privkey: match[0]
        };
}

var storageKey = "trustees";

function loadAll(param) {
  return AsyncStorage.default.getItem(storageKey).then(function (prim) {
                  if (prim === null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }).then(function (__x) {
                return Belt_Option.map(__x, (function (prim) {
                              return JSON.parse(prim);
                            }));
              }).then(function (__x) {
              return Belt_Option.getWithDefault(__x, []);
            });
}

function store_all(trustees) {
  AsyncStorage.default.setItem(storageKey, JSON.stringify(trustees));
}

function clear(param) {
  AsyncStorage.default.removeItem(storageKey);
}

export {
  make ,
  storageKey ,
  loadAll ,
  store_all ,
  clear ,
}
/* Belenios Not a pure module */
