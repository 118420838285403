// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as SIcon from "./icons/SIcon.bs.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactNativePaper from "react-native-paper";

function Header(Props) {
  var titleOpt = Props.title;
  var subtitleOpt = Props.subtitle;
  var titleTextStyle = Props.titleTextStyle;
  var subtitleTextStyle = Props.subtitleTextStyle;
  var title = titleOpt !== undefined ? titleOpt : "";
  var subtitle = subtitleOpt !== undefined ? subtitleOpt : "";
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var state = match[0];
  var titleTextStyle$1 = ReactNative.StyleSheet.flatten([
        S.headerTitle,
        Belt_Option.getWithDefault(titleTextStyle, {})
      ]);
  var subtitleTextStyle$1 = ReactNative.StyleSheet.flatten([
        {
          color: S.primaryColor,
          fontSize: 22.0,
          alignSelf: "center"
        },
        Belt_Option.getWithDefault(subtitleTextStyle, {})
      ]);
  var match$1 = state.route;
  var backButton;
  var exit = 0;
  if (match$1 && !(match$1.hd === "" && !match$1.tl)) {
    exit = 1;
  } else {
    backButton = React.createElement(React.Fragment, undefined);
  }
  if (exit === 1) {
    backButton = React.createElement(ReactNative.TouchableOpacity, {
          style: {
            marginLeft: 40.0,
            marginTop: 40.0
          },
          onPress: (function (param) {
              Curry._1(dispatch, /* Navigate_Back */1);
            }),
          children: React.createElement(SIcon.ButtonBack.make, {})
        });
  }
  var match$2 = state.route;
  var settingsButton;
  var exit$1 = 0;
  if (match$2 && !(match$2.hd === "" && !match$2.tl)) {
    settingsButton = React.createElement(React.Fragment, undefined);
  } else {
    exit$1 = 1;
  }
  if (exit$1 === 1) {
    settingsButton = React.createElement(ReactNativePaper.Appbar.Action, {
          icon: "cog-outline",
          onPress: (function (param) {
              Curry._1(dispatch, {
                    TAG: /* Navigate */0,
                    _0: {
                      hd: "settings",
                      tl: /* [] */0
                    }
                  });
            })
        });
  }
  return React.createElement(ReactNativePaper.Appbar.Header, {
              children: null,
              style: {
                backgroundColor: "white",
                marginBottom: 20.0
              }
            }, backButton, React.createElement(ReactNativePaper.Appbar.Content, {
                  title: React.createElement(React.Fragment, undefined, React.createElement(ReactNativePaper.Title, {
                            style: titleTextStyle$1,
                            children: title
                          }), React.createElement(ReactNativePaper.Text, {
                            style: subtitleTextStyle$1,
                            children: subtitle
                          }))
                }), settingsButton);
}

var make = Header;

export {
  make ,
}
/* S Not a pure module */
