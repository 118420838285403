// Generated by ReScript, PLEASE EDIT WITH CARE

import * as X from "../helpers/X.bs.js";
import * as $$URL from "../helpers/URL.bs.js";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Account from "./Account.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SjclWithAll from "sjcl-with-all";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.bs.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.bs.js";
import * as AsyncStorage from "@react-native-async-storage/async-storage";

var event_type_map = [
  [
    "election.init",
    "election.init"
  ],
  [
    "election.voter",
    "election.voter"
  ],
  [
    "election.delegation",
    "election.delegation"
  ],
  [
    "election.ballot",
    "election.ballot"
  ],
  [
    "election.tally",
    "election.tally"
  ]
];

function hash(str) {
  return SjclWithAll.codec.hex.fromBits(SjclWithAll.hash.sha256.hash(str));
}

function makeEvent(type_, content, account) {
  var cid = SjclWithAll.codec.hex.fromBits(SjclWithAll.hash.sha256.hash(content));
  var emitterId = account.userId;
  var signature = Account.signHex(account, cid);
  return {
          id: 0,
          type_: type_,
          content: content,
          cid: cid,
          emitterId: emitterId,
          signature: signature
        };
}

function create(election, admin) {
  return makeEvent("election.init", JSON.stringify(election), admin);
}

function parse(ev) {
  return JSON.parse(ev.content);
}

var ElectionInit = {
  create: create,
  parse: parse
};

function create$1(ballot, voter) {
  return makeEvent("election.ballot", JSON.stringify(ballot), voter);
}

function parse$1(ev) {
  return JSON.parse(ev.content);
}

var ElectionBallot = {
  create: create$1,
  parse: parse$1
};

function create$2(payload, admin) {
  return makeEvent("election.voter", JSON.stringify(payload), admin);
}

var ElectionVoter = {
  create: create$2
};

function create$3(payload, admin) {
  return makeEvent("election.tally", JSON.stringify(payload), admin);
}

var ElectionTally = {
  create: create$3
};

function create$4(payload, voter) {
  return makeEvent("election.delegation", JSON.stringify(payload), voter);
}

var ElectionDelegate = {
  create: create$4
};

function from_json(json) {
  var decode = Json_Decode$JsonCombinators.object(function (field) {
        var type_str = field.required("type_", Json_Decode$JsonCombinators.string);
        var match = Belt_Array.getBy(event_type_map, (function (param) {
                return param[1] === type_str;
              }));
        if (match !== undefined) {
          return {
                  id: field.required("id", Json_Decode$JsonCombinators.$$int),
                  type_: match[0],
                  content: field.required("content", Json_Decode$JsonCombinators.string),
                  cid: field.required("cid", Json_Decode$JsonCombinators.string),
                  emitterId: field.required("emitterId", Json_Decode$JsonCombinators.string),
                  signature: field.required("signature", Json_Decode$JsonCombinators.string)
                };
        } else {
          return Js_exn.raiseError("Unknown event type");
        }
      });
  var result = Json$JsonCombinators.decode(json, decode);
  if (result.TAG === /* Ok */0) {
    return result._0;
  }
  throw {
        RE_EXN_ID: Json_Decode$JsonCombinators.DecodeError,
        _1: result._0,
        Error: new Error()
      };
}

function to_json(r) {
  var match = Belt_Array.getBy(event_type_map, (function (param) {
          return param[0] === r.type_;
        }));
  if (match !== undefined) {
    return {
            type_: match[1],
            content: r.content,
            cid: r.cid,
            emitterId: r.emitterId,
            signature: r.signature
          };
  } else {
    return Js_exn.raiseError("Unknown event type");
  }
}

var storageKey = "events";

function loadAll(param) {
  return AsyncStorage.default.getItem(storageKey).then(function (prim) {
                  if (prim === null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }).then(function (__x) {
                return Belt_Option.map(__x, (function (prim) {
                              return JSON.parse(prim);
                            }));
              }).then(function (__x) {
              return Belt_Option.getWithDefault(__x, []);
            });
}

function store_all(evs) {
  AsyncStorage.default.setItem(storageKey, JSON.stringify(evs));
}

function clear(param) {
  AsyncStorage.default.removeItem(storageKey);
}

function broadcast(ev) {
  return X.post("" + $$URL.bbs_url + "/events", to_json(ev));
}

export {
  event_type_map ,
  hash ,
  makeEvent ,
  ElectionInit ,
  ElectionBallot ,
  ElectionVoter ,
  ElectionTally ,
  ElectionDelegate ,
  from_json ,
  to_json ,
  storageKey ,
  loadAll ,
  store_all ,
  clear ,
  broadcast ,
}
/* X Not a pure module */
