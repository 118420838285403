// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Header from "./Header.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function SettingsView(Props) {
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = ReactI18next.useTranslation();
  var i18n = match$1.i18n;
  var t = match$1.t;
  var language = i18n.language;
  var language$1 = language === "en-US" ? "en" : language;
  return React.createElement(React.Fragment, undefined, React.createElement(Header.make, {}), React.createElement(ReactNativePaper.List.Section, {
                  title: t("settings.language"),
                  children: React.createElement(ReactNativePaper.SegmentedButtons, {
                        value: language$1,
                        onValueChange: (function (lang) {
                            i18n.changeLanguage(lang);
                            Curry._1(dispatch, {
                                  TAG: /* Config_Store_Language */7,
                                  _0: lang
                                });
                          }),
                        buttons: [
                          {
                            value: "en",
                            label: "English"
                          },
                          {
                            value: "fr",
                            label: "Français"
                          },
                          {
                            value: "nb_NO",
                            label: "Norsk"
                          }
                        ]
                      }),
                  style: S.marginX
                }), React.createElement(ReactNativePaper.List.Section, {
                  title: t("settings.internals"),
                  children: null,
                  style: S.marginX
                }, React.createElement(ReactNativePaper.List.Item, {
                      onPress: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* Navigate */0,
                                _0: {
                                  hd: "identities",
                                  tl: /* [] */0
                                }
                              });
                        }),
                      title: t("settings.identities")
                    }), React.createElement(ReactNativePaper.List.Item, {
                      onPress: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* Navigate */0,
                                _0: {
                                  hd: "trustees",
                                  tl: /* [] */0
                                }
                              });
                        }),
                      title: t("settings.trustees")
                    }), React.createElement(ReactNativePaper.List.Item, {
                      onPress: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* Navigate */0,
                                _0: {
                                  hd: "contacts",
                                  tl: /* [] */0
                                }
                              });
                        }),
                      title: t("settings.contacts")
                    }), React.createElement(ReactNativePaper.List.Item, {
                      onPress: (function (param) {
                          Curry._1(dispatch, {
                                TAG: /* Navigate */0,
                                _0: {
                                  hd: "events",
                                  tl: /* [] */0
                                }
                              });
                        }),
                      title: t("settings.events")
                    })));
}

var make = SettingsView;

export {
  make ,
}
/* S Not a pure module */
