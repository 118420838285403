// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as $$URL from "../helpers/URL.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as State from "../state/State.bs.js";
import * as React from "react";
import * as Event_ from "../model/Event_.bs.js";
import * as Account from "../model/Account.bs.js";
import * as CopyButton from "./utils/CopyButton.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as ElectionHeader from "./ElectionHeader.bs.js";

function ElectionInviteLink(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = ReactI18next.useTranslation();
  var match$2 = React.useState(function () {
        return "";
      });
  var setInviteUrl = match$2[1];
  var inviteUrl = match$2[0];
  var admin = State.getElectionAdminExn(match[0], election);
  React.useEffect((function () {
          var voter = Account.make(undefined);
          var invitation_userId = voter.userId;
          var invitation = {
            userId: invitation_userId,
            email: undefined,
            phoneNumber: undefined
          };
          Curry._1(dispatch, {
                TAG: /* Invitation_Add */5,
                _0: invitation
              });
          var ev = Event_.ElectionVoter.create({
                electionId: electionId,
                voterId: voter.userId
              }, admin);
          Curry._1(dispatch, {
                TAG: /* Event_Add_With_Broadcast */3,
                _0: ev
              });
          Curry._1(setInviteUrl, (function (param) {
                  return "" + $$URL.base_url + "/elections/" + electionId + "/booth#" + voter.secret + "";
                }));
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election,
                  section: "inviteLink"
                }), React.createElement(ReactNative.View, {
                  style: {
                    margin: 30.0
                  },
                  children: React.createElement(S.TextInput.make, {
                        testID: "input-invite-link",
                        value: inviteUrl,
                        onChangeText: (function (param) {
                            
                          })
                      })
                }), React.createElement(CopyButton.make, {
                  text: inviteUrl
                }), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      ReactNative.Share.share({
                            message: inviteUrl
                          });
                    }),
                  title: match$1.t("election.show.createInvite.share")
                }));
}

var make = ElectionInviteLink;

export {
  make ,
}
/* S Not a pure module */
