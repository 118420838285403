// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as SIcon from "./icons/SIcon.bs.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as ElectionNewChoiceItem from "./ElectionNewChoiceItem.bs.js";

function ElectionNewChoiceList(Props) {
  var choices = Props.choices;
  var setChoices = Props.setChoices;
  var match = ReactI18next.useTranslation();
  return React.createElement(ReactNative.View, {
              testID: "choice-list",
              children: null
            }, React.createElement(S.Section.make, {
                  title: match.t("election.new.choiceList.choices")
                }), Belt_Array.mapWithIndex(choices, (function (i, name) {
                    return React.createElement(ElectionNewChoiceItem.make, {
                                onRemove: (function (param) {
                                    Curry._1(setChoices, (function (choices) {
                                            return Belt_Array.keepWithIndex(choices, (function (_name, index) {
                                                          return index !== i;
                                                        }));
                                          }));
                                  }),
                                onUpdate: (function (name) {
                                    Curry._1(setChoices, (function (choices) {
                                            return Belt_Array.mapWithIndex(choices, (function (index, oldName) {
                                                          if (index === i) {
                                                            return name;
                                                          } else {
                                                            return oldName;
                                                          }
                                                        }));
                                          }));
                                  }),
                                name: name,
                                index: i + 1 | 0,
                                key: String(i)
                              });
                  })), React.createElement(ReactNative.TouchableOpacity, {
                  style: {
                    alignSelf: "center"
                  },
                  onPress: (function (param) {
                      Curry._1(setChoices, (function (choices) {
                              return Belt_Array.concat(choices, [""]);
                            }));
                    }),
                  children: React.createElement(SIcon.ButtonPlus.make, {})
                }));
}

var make = ElectionNewChoiceList;

export {
  make ,
}
/* S Not a pure module */
