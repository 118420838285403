// Generated by ReScript, PLEASE EDIT WITH CARE

import * as State from "./State.bs.js";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateEffect from "./StateEffect.bs.js";
import * as ReactNative from "react-native";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";

function reducer(state, action) {
  if (typeof action === "number") {
    switch (action) {
      case /* Reset */0 :
          return [
                  State.initial,
                  [
                    StateEffect.loadAccounts,
                    StateEffect.loadTrustees,
                    StateEffect.loadInvitations,
                    StateEffect.loadAndFetchEvents,
                    StateEffect.goToUrl
                  ]
                ];
      case /* Navigate_Back */1 :
          if (ReactNative.Platform.OS === "web") {
            ((history.back()));
            return [
                    state,
                    []
                  ];
          } else {
            return [
                    {
                      events: state.events,
                      fetchingEvents: state.fetchingEvents,
                      accounts: state.accounts,
                      trustees: state.trustees,
                      invitations: state.invitations,
                      route: /* [] */0,
                      elections: state.elections,
                      electionLatestIds: state.electionLatestIds,
                      ballots: state.ballots
                    },
                    []
                  ];
          }
      case /* Navigate_About */2 :
          if (ReactNative.Platform.OS === "web") {
            ((window.location = "https://scrutin.app"));
          } else {
            ReactNative.Linking.openURL("https://www.scrutin.app");
          }
          return [
                  state,
                  []
                ];
      case /* FetchLatest */3 :
          var latestId = Belt_Array.reduce(state.events, 0, (function (acc, ev) {
                  if (acc > ev.id) {
                    return acc;
                  } else {
                    return ev.id;
                  }
                }));
          return [
                  state,
                  [(function (param) {
                        return StateEffect.fetchLatestEvents(latestId, param);
                      })]
                ];
      case /* Fetched */4 :
          return [
                  {
                    events: state.events,
                    fetchingEvents: false,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: state.invitations,
                    route: state.route,
                    elections: state.elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  []
                ];
      
    }
  } else {
    switch (action.TAG | 0) {
      case /* Navigate */0 :
          var route = action._0;
          if (ReactNative.Platform.OS === "web" && Caml_obj.notequal(route, RescriptReactRouter.dangerouslyGetInitialUrl(undefined, undefined).path)) {
            var path = Belt_List.reduce(route, "", (function (a, b) {
                    return a + "/" + b;
                  }));
            RescriptReactRouter.push(path === "" ? "/" : path);
          }
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: state.invitations,
                    route: route,
                    elections: state.elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  []
                ];
      case /* Account_Add */1 :
          var accounts = Belt_Array.concat(state.accounts, [action._0]);
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: accounts,
                    trustees: state.trustees,
                    invitations: state.invitations,
                    route: state.route,
                    elections: state.elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  [(function (param) {
                        return StateEffect.storeAccounts(accounts, param);
                      })]
                ];
      case /* Event_Add */2 :
          var $$event = action._0;
          if (Belt_Option.isSome(Belt_Array.getBy(state.events, (function (oldEvent) {
                        return $$event.cid === oldEvent.cid;
                      })))) {
            console.log("Duplicated event");
            return [
                    state,
                    []
                  ];
          }
          var events = Belt_Array.concat(state.events, [$$event]);
          var match = StateEffect.electionsUpdate(state.elections, state.ballots, $$event);
          return [
                  {
                    events: events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: state.invitations,
                    route: state.route,
                    elections: match[0],
                    electionLatestIds: state.electionLatestIds,
                    ballots: match[1]
                  },
                  [(function (param) {
                        return StateEffect.storeEvents(events, param);
                      })]
                ];
      case /* Event_Add_With_Broadcast */3 :
          var $$event$1 = action._0;
          var match$1 = reducer(state, {
                TAG: /* Event_Add */2,
                _0: $$event$1
              });
          return [
                  match$1[0],
                  Belt_Array.concat(match$1[1], [(function (param) {
                            return StateEffect.broadcastEvent($$event$1, param);
                          })])
                ];
      case /* Trustee_Add */4 :
          var trustees = Belt_Array.concat(state.trustees, [action._0]);
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: trustees,
                    invitations: state.invitations,
                    route: state.route,
                    elections: state.elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  [(function (param) {
                        return StateEffect.storeTrustees(trustees, param);
                      })]
                ];
      case /* Invitation_Add */5 :
          var invitations = Belt_Array.concat(state.invitations, [action._0]);
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: invitations,
                    route: state.route,
                    elections: state.elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  [(function (param) {
                        return StateEffect.storeInvitations(invitations, param);
                      })]
                ];
      case /* Invitation_Remove */6 :
          var index = action._0;
          var invitations$1 = Belt_Array.keepWithIndex(state.invitations, (function (param, i) {
                  return i !== index;
                }));
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: invitations$1,
                    route: state.route,
                    elections: state.elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  [(function (param) {
                        return StateEffect.storeInvitations(invitations$1, param);
                      })]
                ];
      case /* Config_Store_Language */7 :
          var language = action._0;
          return [
                  state,
                  [(function (param) {
                        return StateEffect.storeLanguage(language, param);
                      })]
                ];
      case /* ElectionInit */8 :
          var election = action._1;
          var cid = action._0;
          var elections = Belt_MapString.set(state.elections, cid, {
                electionId: cid,
                adminIds: election.adminIds,
                voterIds: election.voterIds,
                params: election.params,
                trustees: election.trustees,
                pda: election.pda,
                pdb: election.pdb,
                result: election.result
              });
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: state.invitations,
                    route: state.route,
                    elections: elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  []
                ];
      case /* ElectionUpdate */9 :
          var elections$1 = Belt_MapString.set(state.elections, action._0, action._1);
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: state.invitations,
                    route: state.route,
                    elections: elections$1,
                    electionLatestIds: state.electionLatestIds,
                    ballots: state.ballots
                  },
                  []
                ];
      case /* BallotAdd */10 :
          var ballots = Belt_Array.concat(state.ballots, [action._1]);
          return [
                  {
                    events: state.events,
                    fetchingEvents: state.fetchingEvents,
                    accounts: state.accounts,
                    trustees: state.trustees,
                    invitations: state.invitations,
                    route: state.route,
                    elections: state.elections,
                    electionLatestIds: state.electionLatestIds,
                    ballots: ballots
                  },
                  []
                ];
      
    }
  }
}

export {
  reducer ,
}
/* StateEffect Not a pure module */
