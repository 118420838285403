// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as SIcon from "./icons/SIcon.bs.js";
import * as React from "react";
import * as Header from "./Header.bs.js";
import * as Election from "../model/Election.bs.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactI18next from "react-i18next";
import * as Belt_MapString from "rescript/lib/es6/belt_MapString.js";
import * as ReactNativePaper from "react-native-paper";

function ElectionSearch$Item(Props) {
  var id = Props.id;
  var election = Props.election;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var electionParams = JSON.parse(election.params);
  var name = electionParams.name === "" ? "Unnamed" : electionParams.name;
  return React.createElement(ReactNativePaper.Card, {
              style: S.marginY(8.0),
              children: React.createElement(ReactNativePaper.Card.Content, {
                    children: React.createElement(ReactNativePaper.List.Section, {
                          title: "",
                          children: React.createElement(ReactNativePaper.List.Item, {
                                onPress: (function (param) {
                                    Curry._1(dispatch, {
                                          TAG: /* Navigate */0,
                                          _0: {
                                            hd: "elections",
                                            tl: {
                                              hd: id,
                                              tl: /* [] */0
                                            }
                                          }
                                        });
                                  }),
                                title: name,
                                description: electionParams.description
                              })
                        })
                  })
            });
}

var Item = {
  make: ElectionSearch$Item
};

function ElectionSearch(Props) {
  var match = StateContext.use(undefined);
  var state = match[0];
  var match$1 = ReactI18next.useTranslation();
  var match$2 = React.useState(function () {
        return "";
      });
  var setQuery = match$2[1];
  var query = match$2[0];
  var onSearch = function (param) {
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement(Header.make, {
                  title: match$1.t("search.header.title")
                }), React.createElement(S.Row.make, {
                  children: null,
                  style: {
                    marginHorizontal: 20.0
                  }
                }, React.createElement(S.Col.make, {
                      children: React.createElement(S.TextInput.make, {
                            label: "Search something...",
                            testID: "election-search",
                            value: query,
                            onChangeText: (function (text) {
                                Curry._1(setQuery, (function (param) {
                                        return text;
                                      }));
                              })
                          }),
                      style: {
                        flexGrow: 8.0
                      }
                    }), React.createElement(S.Col.make, {
                      children: React.createElement(ReactNativePaper.Button, {
                            onPress: onSearch,
                            children: React.createElement(SIcon.ButtonSearch.make, {})
                          })
                    })), Belt_Array.map(Belt_Array.keep(Belt_Array.keep(Belt_MapString.toArray(state.elections), (function (param) {
                            return Belt_Option.isNone(Belt_MapString.get(state.electionLatestIds, param[0]));
                          })), (function (param) {
                        var election = param[1];
                        var name = Election.name(election).toLowerCase();
                        var question = Election.description(election).toLowerCase();
                        if (Js_string.indexOf(query.toLowerCase(), name) !== -1) {
                          return true;
                        } else {
                          return Js_string.indexOf(query.toLowerCase(), question) !== -1;
                        }
                      })), (function (param) {
                    var id = param[0];
                    return React.createElement(ElectionSearch$Item, {
                                id: id,
                                election: param[1],
                                key: id
                              });
                  })));
}

var make = ElectionSearch;

export {
  Item ,
  make ,
}
/* S Not a pure module */
