// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Logo from "./utils/Logo.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as SIcon from "./icons/SIcon.bs.js";
import * as React from "react";
import * as Header from "./Header.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as HomeBackground from "./utils/HomeBackground.bs.js";
import * as ReactNativePaper from "react-native-paper";

function HomeView(Props) {
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = ReactI18next.useTranslation();
  var t = match$1.t;
  var styles = ReactNative.StyleSheet.create({
        aboutView: {
          left: 145.0,
          position: "absolute",
          top: 150.0
        },
        aboutButton: {
          height: 80.0,
          width: 80.0
        },
        searchView: {
          position: "absolute",
          right: 145.0,
          top: 150.0
        },
        searchButton: {
          height: 80.0,
          width: 80.0
        }
      });
  var match$2 = ReactNative.Platform.OS;
  return React.createElement(ReactNative.View, {
              children: null
            }, React.createElement(Header.make, {}), React.createElement(Logo.make, {}), React.createElement(S.Button.make, {
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Navigate */0,
                            _0: {
                              hd: "elections",
                              tl: {
                                hd: "new",
                                tl: /* [] */0
                              }
                            }
                          });
                    }),
                  title: t("home.create"),
                  style: {
                    paddingVertical: 12.0,
                    width: 300.0
                  },
                  titleStyle: {
                    fontSize: 18.0
                  }
                }), match$2 === "web" ? React.createElement(ReactNativePaper.Button, {
                    mode: "text",
                    onPress: (function (param) {
                        Curry._1(dispatch, {
                              TAG: /* Navigate */0,
                              _0: {
                                hd: "elections",
                                tl: {
                                  hd: "search",
                                  tl: /* [] */0
                                }
                              }
                            });
                      }),
                    children: t("home.search")
                  }) : React.createElement(React.Fragment, undefined, React.createElement(ReactNative.View, {
                        style: {
                          height: 50.0
                        }
                      }), React.createElement(ReactNative.View, {
                        children: null
                      }, React.createElement(ReactNative.View, {
                            children: React.createElement(HomeBackground.make, {})
                          }), React.createElement(ReactNative.TouchableOpacity, {
                            style: styles.aboutView,
                            onPress: (function (param) {
                                Curry._1(dispatch, /* Navigate_About */2);
                              }),
                            children: React.createElement(SIcon.ButtonAbout.make, {})
                          }), React.createElement(ReactNative.TouchableOpacity, {
                            style: styles.searchView,
                            onPress: (function (param) {
                                Curry._1(dispatch, {
                                      TAG: /* Navigate */0,
                                      _0: {
                                        hd: "elections",
                                        tl: {
                                          hd: "search",
                                          tl: /* [] */0
                                        }
                                      }
                                    });
                              }),
                            children: React.createElement(SIcon.ButtonSearch.make, {})
                          }))));
}

var make = HomeView;

export {
  make ,
}
/* S Not a pure module */
