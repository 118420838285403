// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Event_ from "../model/Event_.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function EventIndex$Item(Props) {
  var $$event = Props.event;
  StateContext.use(undefined);
  var match = ReactI18next.useTranslation();
  var event_type = Belt_Option.map(Belt_Array.getBy(Event_.event_type_map, (function (param) {
              return param[0] === $$event.type_;
            })), (function (param) {
          return param[1];
        }));
  var description = event_type !== undefined ? event_type : "Unknown type";
  var onPress = function (param) {
    
  };
  return React.createElement(ReactNativePaper.Card, {
              children: React.createElement(ReactNativePaper.List.Item, {
                    onPress: onPress,
                    title: match.t("events.item.type"),
                    description: description,
                    key: $$event.cid
                  })
            });
}

var Item = {
  make: EventIndex$Item
};

function EventIndex(Props) {
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = ReactI18next.useTranslation();
  var t = match$1.t;
  var clear = function (param) {
    Event_.clear(undefined);
    Curry._1(dispatch, /* Reset */0);
  };
  return React.createElement(ReactNativePaper.List.Section, {
              title: t("events.title"),
              children: null
            }, Belt_Array.map(match[0].events, (function ($$event) {
                    return React.createElement(EventIndex$Item, {
                                event: $$event,
                                key: $$event.cid
                              });
                  })), React.createElement(S.Title.make, {
                  children: "-"
                }), React.createElement(ReactNativePaper.Button, {
                  mode: "contained",
                  onPress: clear,
                  children: t("events.clear")
                }));
}

var make = EventIndex;

export {
  Item ,
  make ,
}
/* S Not a pure module */
