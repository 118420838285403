// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as React from "react";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactNativePaper from "react-native-paper";

function Layout(Props) {
  var state = Props.state;
  var dispatch = Props.dispatch;
  var children = Props.children;
  return React.createElement(ReactNativePaper.Provider, {
              children: React.createElement(StateContext.State.Provider.make, {
                    value: state,
                    children: React.createElement(StateContext.Dispatch.Provider.make, {
                          value: dispatch,
                          children: React.createElement(ReactNative.SafeAreaView, {
                                style: S.layout,
                                children: React.createElement(ReactNative.ScrollView, {
                                      children: children
                                    })
                              })
                        })
                  }),
              theme: {
                dark: true
              }
            });
}

var make = Layout;

export {
  make ,
}
/* S Not a pure module */
