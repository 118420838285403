// Generated by ReScript, PLEASE EDIT WITH CARE

import ButtonBack from "./ButtonBack";
import ButtonPlus from "./ButtonPlus";
import ButtonAbout from "./ButtonAbout";
import ButtonCross from "./ButtonCross";
import ButtonSearch from "./ButtonSearch";

var make = ButtonBack;

var ButtonBack$1 = {
  make: make
};

var make$1 = ButtonAbout;

var ButtonAbout$1 = {
  make: make$1
};

var make$2 = ButtonCross;

var ButtonCross$1 = {
  make: make$2
};

var make$3 = ButtonPlus;

var ButtonPlus$1 = {
  make: make$3
};

var make$4 = ButtonSearch;

var ButtonSearch$1 = {
  make: make$4
};

export {
  ButtonBack$1 as ButtonBack,
  ButtonAbout$1 as ButtonAbout,
  ButtonCross$1 as ButtonCross,
  ButtonPlus$1 as ButtonPlus,
  ButtonSearch$1 as ButtonSearch,
}
/* make Not a pure module */
