// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Header from "./Header.bs.js";
import * as Election from "../model/Election.bs.js";
import * as ReactI18next from "react-i18next";

function ElectionHeader(Props) {
  var election = Props.election;
  var sectionOpt = Props.section;
  var section = sectionOpt !== undefined ? sectionOpt : "index";
  var match = ReactI18next.useTranslation();
  var electionName = Election.name(election);
  var title = electionName === "" ? match.t("election.show.unnamed") : electionName;
  var match$1 = Election.name(election);
  var titleTextStyle = match$1 === "" ? ({
        color: "grey"
      }) : ({});
  var subtitle = section === "invite" ? "Ajouter des votants" : (
      section === "inviteLink" ? "Invitation par lien" : (
          section === "inviteMail" ? "Invitation mail" : (
              section === "inviteManage" ? "Manage invitations" : (
                  section === "index" ? "" : "Résultats"
                )
            )
        )
    );
  return React.createElement(Header.make, {
              title: title,
              subtitle: subtitle,
              titleTextStyle: titleTextStyle
            });
}

var make = ElectionHeader;

export {
  make ,
}
/* react Not a pure module */
