// Generated by ReScript, PLEASE EDIT WITH CARE

import HomeBackgroundJs from "./HomeBackgroundJs";

var make = HomeBackgroundJs;

export {
  make ,
}
/* make Not a pure module */
