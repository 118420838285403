// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Sjcl from "../helpers/Sjcl.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SjclWithAll from "sjcl-with-all";
import * as AsyncStorage from "@react-native-async-storage/async-storage";

function make(param) {
  var match = Sjcl.Ecdsa.$$new(undefined);
  return {
          userId: Curry._1(Sjcl.Ecdsa.PublicKey.toHex, match[0]),
          secret: Curry._1(Sjcl.Ecdsa.SecretKey.toHex, match[1])
        };
}

function make2(secret) {
  var sec = SjclWithAll.bn.fromBits(SjclWithAll.codec.hex.toBits(secret));
  var keys = Sjcl.Ecdsa.generateKeysFromSecretKey(sec);
  var userId = Curry._1(Sjcl.Ecdsa.PublicKey.toHex, keys.pub);
  return {
          userId: userId,
          secret: secret
        };
}

function signHex(account, hexStr) {
  var secretKey = Curry._1(Sjcl.Ecdsa.SecretKey.fromHex, account.secret);
  var baEventHash = SjclWithAll.codec.hex.toBits(hexStr);
  return SjclWithAll.codec.hex.fromBits(secretKey.sign(baEventHash));
}

var storageKey = "accounts";

function loadAll(param) {
  return AsyncStorage.default.getItem(storageKey).then(function (prim) {
                  if (prim === null) {
                    return ;
                  } else {
                    return Caml_option.some(prim);
                  }
                }).then(function (__x) {
                return Belt_Option.map(__x, (function (prim) {
                              return JSON.parse(prim);
                            }));
              }).then(function (__x) {
              return Belt_Option.getWithDefault(__x, []);
            });
}

function store_all(accounts) {
  AsyncStorage.default.setItem(storageKey, JSON.stringify(accounts));
}

function clear(param) {
  AsyncStorage.default.removeItem(storageKey);
}

export {
  make ,
  make2 ,
  signHex ,
  storageKey ,
  loadAll ,
  store_all ,
  clear ,
}
/* Sjcl Not a pure module */
