// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Invitation from "../model/Invitation.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function ContactIndex(Props) {
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = ReactI18next.useTranslation();
  var t = match$1.t;
  return React.createElement(React.Fragment, undefined, React.createElement(S.Title.make, {
                  children: t("invitation.title")
                }), React.createElement(ReactNativePaper.List.Section, {
                  title: "",
                  children: Belt_Array.mapWithIndex(match[0].invitations, (function (i, invitation) {
                          return React.createElement(ReactNativePaper.Card, {
                                      children: null,
                                      key: invitation.userId
                                    }, React.createElement(ReactNativePaper.Card.Content, {
                                          children: null
                                        }, React.createElement(ReactNativePaper.List.Item, {
                                              title: t("invitation.item.email"),
                                              description: Belt_Option.getWithDefault(invitation.email, "")
                                            }), React.createElement(ReactNativePaper.List.Item, {
                                              title: t("invitation.item.phoneNumber"),
                                              description: Belt_Option.getWithDefault(invitation.phoneNumber, "")
                                            }), React.createElement(ReactNativePaper.List.Item, {
                                              title: "0x" + invitation.userId
                                            })), React.createElement(ReactNativePaper.Card.Actions, {
                                          children: React.createElement(ReactNativePaper.Button, {
                                                mode: "contained",
                                                onPress: (function (param) {
                                                    Curry._1(dispatch, {
                                                          TAG: /* Invitation_Remove */6,
                                                          _0: i
                                                        });
                                                  }),
                                                children: t("invitation.delete")
                                              })
                                        }));
                        })),
                  style: S.marginX
                }), React.createElement(ReactNativePaper.Button, {
                  mode: "outlined",
                  onPress: (function (param) {
                      Invitation.clear(undefined);
                      Curry._1(dispatch, /* Reset */0);
                    }),
                  children: t("invitation.clearAll")
                }), React.createElement(S.Title.make, {
                  children: "-"
                }));
}

var make = ContactIndex;

export {
  make ,
}
/* S Not a pure module */
