// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as X from "../helpers/X.bs.js";
import * as $$URL from "../helpers/URL.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Event_ from "../model/Event_.bs.js";
import * as Account from "../model/Account.bs.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ElectionHeader from "./ElectionHeader.bs.js";

function ElectionChallenge(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var userToken = Props.userToken;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = React.useState(function () {
        return userToken;
      });
  var setUserToken = match$1[1];
  var userToken$1 = match$1[0];
  var submit = function (param) {
    var account = Account.make(undefined);
    Curry._1(dispatch, {
          TAG: /* Account_Add */1,
          _0: account
        });
    var dict = {};
    dict["userId"] = account.userId;
    dict["userToken"] = userToken$1;
    X.post("" + $$URL.server_auth_email + "/challenge", dict).then(function (prim) {
              return prim.json();
            }).then(function (json) {
            var ev = Event_.from_json(json);
            Curry._1(dispatch, {
                  TAG: /* Event_Add_With_Broadcast */3,
                  _0: ev
                });
            Curry._1(dispatch, {
                  TAG: /* Navigate */0,
                  _0: {
                    hd: "elections",
                    tl: {
                      hd: electionId,
                      tl: {
                        hd: "booth",
                        tl: /* [] */0
                      }
                    }
                  }
                });
          }).then(function (prim) {
          console.log(prim);
        });
  };
  React.useEffect((function () {
          if (userToken$1 !== "") {
            submit(undefined);
          }
          
        }), []);
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election
                }), React.createElement(S.Title.make, {
                  children: "Invitation par token"
                }), React.createElement(S.TextInput.make, {
                  testID: "input-token",
                  value: userToken$1,
                  onChangeText: (function (text) {
                      Curry._1(setUserToken, (function (param) {
                              return text;
                            }));
                    })
                }), React.createElement(S.Button.make, {
                  onPress: submit,
                  title: "Utiliser"
                }));
}

var make = ElectionChallenge;

export {
  make ,
}
/* S Not a pure module */
