// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Webapi__Fetch from "rescript-webapi/src/Webapi/Webapi__Fetch.bs.js";

function post(url, json) {
  var headers = {
    "Content-Type": "application/json"
  };
  var body = JSON.stringify(json);
  return fetch(url, Webapi__Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(body), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined));
}

function ev($$event) {
  return $$event.target.value;
}

function prevent(f, e) {
  e.preventDefault();
  return Curry._1(f, e);
}

var isKeyEnter = (function(key) { return key.key == "Enter" });

var match = process.env.NODE_ENV;

var env;

switch (match) {
  case "development" :
      env = "dev";
      break;
  case "production" :
      env = "prod";
      break;
  default:
    env = "dev";
}

export {
  post ,
  ev ,
  prevent ,
  isKeyEnter ,
  env ,
}
/* match Not a pure module */
