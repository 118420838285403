// Generated by ReScript, PLEASE EDIT WITH CARE

import * as X from "./X.bs.js";
import * as React from "react";
import * as Dimension from "./Dimension.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactNative from "react-native";
import * as Color$ReactNative from "rescript-react-native/src/apis/Color.bs.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as ReactNativePaper from "react-native-paper";

var primaryColor = X.env === "dev" ? Color$ReactNative.rgb(255, 128, 128) : Color$ReactNative.rgb(103, 80, 164);

function flatten(prim) {
  return ReactNative.StyleSheet.flatten(prim);
}

var title = {
  color: "black",
  fontFamily: "Inter_400Regular",
  fontSize: 20.0,
  textAlign: "center"
};

var match = ReactNative.Platform.OS;

var headerTitle = match === "web" ? ({
      color: primaryColor,
      fontFamily: "Inter_700Bold",
      fontSize: 28.0,
      fontWeight: "900",
      lineHeight: 24.0,
      alignSelf: "center",
      marginTop: 45.0
    }) : ({
      color: primaryColor,
      fontFamily: "Inter_700Bold",
      fontSize: 20.0,
      fontWeight: "300",
      alignSelf: "center",
      marginTop: 20.0
    });

var marginX = {
  marginLeft: 15.0,
  marginRight: 15.0
};

function marginY(size) {
  return {
          marginBottom: size,
          marginTop: size
        };
}

function widthPct(size) {
  return {
          width: Style$ReactNative.pct(size)
        };
}

var modal = {
  backgroundColor: "white",
  margin: 10.0,
  padding: 10.0
};

var section = {
  fontFamily: "Inter_400Regular",
  fontSize: 20.0,
  marginBottom: 15.0,
  marginLeft: 60.0,
  marginTop: 15.0
};

var layout = ReactNative.Platform.OS === "web" && Dimension.width(undefined) > 800 ? ({
      alignSelf: "center",
      width: 800.0
    }) : ({});

var questionBox = {
  borderColor: primaryColor,
  borderWidth: 5.0,
  margin: 30.0
};

function S$Section(Props) {
  var title = Props.title;
  return React.createElement(ReactNativePaper.Title, {
              style: section,
              children: title
            });
}

var Section = {
  make: S$Section
};

function S$Title(Props) {
  var children = Props.children;
  return React.createElement(ReactNativePaper.Title, {
              style: title,
              children: children
            });
}

var Title = {
  make: S$Title
};

function S$Row(Props) {
  var children = Props.children;
  var style = Props.style;
  var style$1 = ReactNative.StyleSheet.flatten([
        {
          flexDirection: "row",
          padding: 10.0
        },
        Belt_Option.getWithDefault(style, {})
      ]);
  return React.createElement(ReactNative.View, {
              style: style$1,
              children: children
            });
}

var Row = {
  make: S$Row
};

function S$Col(Props) {
  var children = Props.children;
  var style = Props.style;
  var style$1 = ReactNative.StyleSheet.flatten([
        {
          flex: 1.0,
          padding: 5.0
        },
        Belt_Option.getWithDefault(style, {})
      ]);
  return React.createElement(ReactNative.View, {
              style: style$1,
              children: children
            });
}

var Col = {
  make: S$Col
};

var SegmentedButtons = {};

function S$Button(Props) {
  var onPress = Props.onPress;
  var title = Props.title;
  var style = Props.style;
  var titleStyle = Props.titleStyle;
  var testID = Props.testID;
  var defaultStyle = {
    borderRadius: 0.0,
    alignSelf: "center",
    marginTop: 25.0,
    paddingVertical: 5.0,
    width: 300.0
  };
  var defaultTitleStyle = {
    color: "white",
    fontFamily: "Inter_400Regular",
    fontSize: 20.0
  };
  var style$1 = style !== undefined ? ReactNative.StyleSheet.flatten([
          defaultStyle,
          Caml_option.valFromOption(style)
        ]) : defaultStyle;
  var titleStyle$1 = titleStyle !== undefined ? ReactNative.StyleSheet.flatten([
          defaultTitleStyle,
          Caml_option.valFromOption(titleStyle)
        ]) : defaultTitleStyle;
  var tmp = {
    style: titleStyle$1,
    children: title
  };
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  return React.createElement(ReactNativePaper.Button, {
              mode: "contained",
              style: style$1,
              onPress: onPress,
              children: React.createElement(ReactNativePaper.Text, tmp)
            });
}

var Button = {
  make: S$Button
};

function S$TextInput(Props) {
  var label = Props.label;
  var testID = Props.testID;
  var value = Props.value;
  var onChangeText = Props.onChangeText;
  var placeholder = Props.placeholder;
  var style = {
    backgroundColor: "white",
    borderWidth: 1.0,
    shadowRadius: 2.0,
    marginHorizontal: 25.0
  };
  var tmp = {
    mode: "flat",
    value: value,
    style: style,
    onChangeText: onChangeText
  };
  if (label !== undefined) {
    tmp.label = label;
  }
  if (placeholder !== undefined) {
    tmp.placeholder = placeholder;
  }
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  return React.createElement(ReactNativePaper.TextInput, tmp);
}

var TextInput = {
  make: S$TextInput
};

export {
  primaryColor ,
  flatten ,
  title ,
  headerTitle ,
  marginX ,
  marginY ,
  widthPct ,
  modal ,
  section ,
  layout ,
  questionBox ,
  Section ,
  Title ,
  Row ,
  Col ,
  SegmentedButtons ,
  Button ,
  TextInput ,
}
/* primaryColor Not a pure module */
