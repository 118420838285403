// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Account from "../model/Account.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ReactI18next from "react-i18next";
import * as ReactNativePaper from "react-native-paper";

function IdentityIndex$Modal_Import(Props) {
  var visible = Props.visible;
  var setVisible = Props.setVisible;
  var onImport = Props.onImport;
  var match = ReactI18next.useTranslation();
  var t = match.t;
  var match$1 = React.useState(function () {
        return "";
      });
  var setImportedPrivateKey = match$1[1];
  var importedPrivateKey = match$1[0];
  return React.createElement(ReactNativePaper.Portal, {
              children: React.createElement(ReactNativePaper.Modal, {
                    visible: visible,
                    onDismiss: (function (param) {
                        Curry._1(setVisible, (function (param) {
                                return false;
                              }));
                      }),
                    children: React.createElement(ReactNative.View, {
                          style: S.flatten([
                                S.modal,
                                S.layout
                              ]),
                          testID: "choice-modal",
                          children: null
                        }, React.createElement(ReactNativePaper.TextInput, {
                              mode: "flat",
                              autoFocus: true,
                              label: t("identity.home.modal.privateKey"),
                              value: importedPrivateKey,
                              onChangeText: (function (text) {
                                  Curry._1(setImportedPrivateKey, (function (param) {
                                          return text;
                                        }));
                                }),
                              onSubmitEditing: (function (param) {
                                  Curry._1(onImport, importedPrivateKey);
                                  Curry._1(setVisible, (function (param) {
                                          return false;
                                        }));
                                }),
                              testID: "input-import-private-key"
                            }), React.createElement(ReactNativePaper.Button, {
                              mode: "contained",
                              onPress: (function (param) {
                                  Curry._1(onImport, importedPrivateKey);
                                  Curry._1(setVisible, (function (param) {
                                          return false;
                                        }));
                                }),
                              children: t("identity.home.modal.add")
                            }))
                  })
            });
}

var Modal_Import = {
  make: IdentityIndex$Modal_Import
};

function IdentityIndex(Props) {
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var match$1 = ReactI18next.useTranslation();
  var t = match$1.t;
  var match$2 = React.useState(function () {
        return false;
      });
  var setVisibleImportModal = match$2[1];
  return React.createElement(React.Fragment, undefined, React.createElement(S.Title.make, {
                  children: t("identity.home.title")
                }), React.createElement(ReactNativePaper.List.Section, {
                  title: "",
                  children: Belt_Array.map(match[0].accounts, (function (id) {
                          return React.createElement(ReactNativePaper.Card, {
                                      children: React.createElement(ReactNativePaper.List.Item, {
                                            onPress: (function (param) {
                                                Curry._1(dispatch, {
                                                      TAG: /* Navigate */0,
                                                      _0: {
                                                        hd: "identities",
                                                        tl: {
                                                          hd: id.userId,
                                                          tl: /* [] */0
                                                        }
                                                      }
                                                    });
                                              }),
                                            title: "0x" + id.userId
                                          }),
                                      key: id.userId
                                    });
                        })),
                  style: S.marginX
                }), React.createElement(S.Title.make, {
                  children: "-"
                }), React.createElement(ReactNativePaper.Button, {
                  mode: "contained",
                  onPress: (function (param) {
                      Curry._1(dispatch, {
                            TAG: /* Account_Add */1,
                            _0: Account.make(undefined)
                          });
                    }),
                  children: t("identity.home.generate")
                }), React.createElement(S.Title.make, {
                  children: "-"
                }), React.createElement(ReactNativePaper.Button, {
                  mode: "contained",
                  onPress: (function (param) {
                      Curry._1(setVisibleImportModal, (function (param) {
                              return true;
                            }));
                    }),
                  children: t("identity.home.import")
                }), React.createElement(S.Title.make, {
                  children: "-"
                }), React.createElement(ReactNativePaper.Button, {
                  mode: "outlined",
                  onPress: (function (param) {
                      Account.clear(undefined);
                      Curry._1(dispatch, /* Reset */0);
                    }),
                  children: t("identity.home.clear")
                }), React.createElement(S.Title.make, {
                  children: "-"
                }), React.createElement(IdentityIndex$Modal_Import, {
                  visible: match$2[0],
                  setVisible: setVisibleImportModal,
                  onImport: (function (secret) {
                      Curry._1(dispatch, {
                            TAG: /* Account_Add */1,
                            _0: Account.make2(secret)
                          });
                    })
                }));
}

var make = IdentityIndex;

export {
  Modal_Import ,
  make ,
}
/* S Not a pure module */
