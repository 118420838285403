// Generated by ReScript, PLEASE EDIT WITH CARE

import * as S from "../helpers/S.bs.js";
import * as Core from "../Core.bs.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as State from "../state/State.bs.js";
import * as React from "react";
import * as StateContext from "../state/StateContext.bs.js";
import * as ReactNative from "react-native";
import * as ElectionHeader from "./ElectionHeader.bs.js";
import * as ReactNativePaper from "react-native-paper";
import * as ElectionShowChoices from "./ElectionShowChoices.bs.js";

function ElectionShow(Props) {
  var election = Props.election;
  var electionId = Props.electionId;
  var match = StateContext.use(undefined);
  var dispatch = match[1];
  var state = match[0];
  var nbVotes = State.getElectionValidBallots(state, electionId).length;
  var match$1 = ReactNative.Platform.OS;
  var match$2 = ReactNative.Platform.OS;
  var match$3 = election.result;
  var tmp;
  if (match$3 !== undefined) {
    tmp = React.createElement(S.Button.make, {
          onPress: (function (param) {
              Curry._1(dispatch, {
                    TAG: /* Navigate */0,
                    _0: {
                      hd: "elections",
                      tl: {
                        hd: electionId,
                        tl: {
                          hd: "result",
                          tl: /* [] */0
                        }
                      }
                    }
                  });
            }),
          title: "Afficher le résultat"
        });
  } else {
    var _adminAccount = State.getElectionAdmin(state, election);
    tmp = _adminAccount !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(S.Button.make, {
                onPress: (function (param) {
                    Curry._1(dispatch, {
                          TAG: /* Navigate */0,
                          _0: {
                            hd: "elections",
                            tl: {
                              hd: electionId,
                              tl: {
                                hd: "invite",
                                tl: /* [] */0
                              }
                            }
                          }
                        });
                  }),
                title: "Ajouter des votants",
                testID: "button-invite"
              }), React.createElement(S.Button.make, {
                onPress: (function (param) {
                    Core.Election.tally(electionId, state, dispatch);
                  }),
                title: "Calculer le résultat des " + String(nbVotes) + " votes"
              })) : React.createElement(React.Fragment, undefined);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(ElectionHeader.make, {
                  election: election
                }), React.createElement(ReactNative.View, {
                  children: React.createElement(ElectionShowChoices.make, {
                        election: election
                      })
                }), React.createElement(ReactNative.View, {
                  style: {
                    marginTop: -30.0
                  },
                  children: React.createElement(ReactNative.View, {
                        style: {
                          position: "absolute",
                          right: 30.0
                        },
                        children: React.createElement(ReactNativePaper.Text, {
                              style: {
                                color: "white",
                                backgroundColor: S.primaryColor,
                                paddingBottom: 5.0,
                                paddingLeft: 8.0,
                                width: match$1 === "web" ? 80.0 : 120.0
                              },
                              children: "Vote privé"
                            })
                      })
                }), match$2 === "web" ? React.createElement(React.Fragment, undefined) : React.createElement(ReactNative.View, {
                    style: {
                      height: 30.0
                    }
                  }), tmp);
}

var make = ElectionShow;

export {
  make ,
}
/* S Not a pure module */
